import React, { Component } from 'react'
import './hometitle.css';


export class HomeTitle extends Component {
  render() {
    return (
      <>
        <h1 className='text-3xl font-grotesk ml-10 mt-10'>Jae Sang Yoo</h1>

      </>
    )
  }
}

export default HomeTitle